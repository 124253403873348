




















































import {Component, Vue} from 'vue-property-decorator';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";

@Component

export default class Chord extends Vue {

  private patterns = []
  private selectedPatterns = []
  private matchingPatterns = [] as any
  private tags = []
  private names = ['']
  private noRelationPatterns = []

  private deleteSelection(){
    this.selectedPatterns = []
    this.getChartData()
  }

  private createTableNoRelationship(){
    let data = [] as any
    Promise.all([
      fetch(`${this.$store.state.root}/api/patterns`, {
        method: 'GET'
      })]).then(
        async resp => {
          const patternsData = await resp[0].json()
          for(let i = 0; i < patternsData.length; i++){
            if(patternsData[i].uses == null && patternsData[i].combines == null && patternsData[i].similar == null){
              data.push(patternsData[i].name)
            }
          }
          this.noRelationPatterns = data
    })
  }

  private updateChart(){
    this.matchingPatterns = [] as any
    if(this.selectedPatterns[0] == null){
      this.getChartData()
    } else {
      // Filter for needed Patterndata (Note: in selectedPatterns are just Patternnames)
      Promise.all([
        fetch(`${this.$store.state.root}/api/patterns`, {
          method: 'GET'
        })]).then(
            async resp => {
              const patternsData = await resp[0].json()
              for(let z = 0; z < this.selectedPatterns.length; z++){
                for(let y = 0; y < patternsData.length; y++){
                  if(this.selectedPatterns[z] == patternsData[y].name){
                    this.matchingPatterns.push(patternsData[y])
                  }
                }
              }
            // Generate new Chart
              let data = [] as any
              for(let i = 0; i < this.matchingPatterns.length; i++){
                let s = this.matchingPatterns[i].uses
                if (s != "" && s != null) {
                  if (s.includes(",")) {
                    let result = s.split(",")
                    for (let j = 0; j < result.length; j++) {
                      data.push({from: this.matchingPatterns[i].name, toolTip: "Uses", linkColor: "#00d1b2", to: result[j].replace(/\s+/g,''), value: 1})
                    }
                  } else {
                    data.push({from: this.matchingPatterns[i].name, toolTip: "Uses", linkColor: "#00d1b2", to: this.matchingPatterns[i].uses, value: 1})
                  }
                }
                s = this.matchingPatterns[i].similar
                if (s != "" && s != null) {
                  if (s.includes(",")) {
                    let result = s.split(",")
                    for (let j = 0; j < result.length; j++) {
                      data.push({from: this.matchingPatterns[i].name, toolTip: "Similar", linkColor: "#f14668", to: result[j].replace(/\s+/g,''), value: 1})
                    }
                  } else {
                    data.push({
                      from: this.matchingPatterns[i].name,
                      toolTip: "Similar",
                      linkColor: "#f14668",
                      to: this.matchingPatterns[i].similar,
                      value: 1
                    })
                  }
                }
                s = this.matchingPatterns[i].combines
                if (s != "" && s != null) {
                  if (s.includes(",")) {
                    let result = s.split(",")
                    for (let j = 0; j < result.length; j++) {
                      data.push({from: this.matchingPatterns[i].name, toolTip: "Combines", linkColor: "#ffdd57", to: result[j].replace(/\s+/g,''), value: 1})
                    }
                  } else {
                    data.push({
                      from: this.matchingPatterns[i].name,
                      toolTip: "Combines",
                      linkColor: "#ffdd57",
                      to: this.matchingPatterns[i].combines,
                      value: 1
                    })
                  }
                }
              }
              this.createChart(data)
            }
      )
    }
  }

  private async getChartData(){
    Promise.all([
      fetch(`${this.$store.state.root}/api/patterns`, {
        method: 'GET'
      }),
      fetch(`${this.$store.state.root}/api/tags/`, {
        method: 'GET'
      })
    ]).then( async allResponses => {
          // get PatternData and process Chartdata
          const patternData = await allResponses[0].json()
          this.patterns = patternData
          let data = [] as any
          for (let i = 0; i < patternData.length; i++) {
            this.names[i] = patternData[i].name
            let s = patternData[i].uses
            if (s != "" && s != null) {
              if (s.includes(",")) {
                let result = s.split(",")
                for (let j = 0; j < result.length; j++) {
                  data.push({from: this.names[i], toolTip: "Uses", linkColor: "#00d1b2", to: result[j].replace(/\s+/g,''), value: 1})
                }
              } else {
                data.push({from: this.names[i], toolTip: "Uses", linkColor: "#00d1b2", to: patternData[i].uses, value: 1})
              }
            }
            s = patternData[i].similar
            if (s != "" && s != null) {
              if (s.includes(",")) {
                let result = s.split(",")
                for (let j = 0; j < result.length; j++) {
                  data.push({from: this.names[i], toolTip: "Similar", linkColor: "#f14668", to: result[j].replace(/\s+/g,''), value: 1})
                }
              } else {
                data.push({
                  from: this.names[i],
                  toolTip: "Similar",
                  linkColor: "#f14668",
                  to: patternData[i].similar,
                  value: 1
                })
              }
            }
            s = patternData[i].combines
            if (s != "" && s != null) {
              if (s.includes(",")) {
                let result = s.split(",")
                for (let j = 0; j < result.length; j++) {
                  data.push({from: this.names[i], toolTip: "Combines", linkColor: "#ffdd57", to: result[j].replace(/\s+/g,''), value: 1})
                }
              } else {
                data.push({
                  from: this.names[i],
                  toolTip: "Combines",
                  linkColor: "#ffdd57",
                  to: patternData[i].combines,
                  value: 1
                })
              }
            }

          }
          // get TagsData
          this.tags = await allResponses[1].json()
          this.createChart(data)
        }
    )
  }

  private createChart(d: []){
    // Create Chart
    let container1 = am4core.create("chart1", am4core.Container);
    container1.width = am4core.percent(100);
    container1.height = am4core.percent(100);
    container1.paddingBottom = 50;
    container1.paddingTop = 50;
    container1.paddingLeft = 50;
    container1.paddingRight = 50;
    container1.removeChildren()

    let chart = container1.createChild(am4charts.ChordDiagram)
    chart.hiddenState.properties.opacity = 0;
    chart.data = d
    chart.dataFields.fromName = "from";
    chart.dataFields.toName = "to";
    chart.dataFields.value = "value";
    chart.dataFields.color = "#363636";
    chart.nodePadding = 1;
    chart.innerRadius = -7;
    chart.minNodeSize = 0.01;

    let link = chart.links.template;
    link.strokeOpacity = 0;
    link.fillOpacity = 0.2;
    link.colorMode = "solid";
    link.propertyFields.fill = "linkColor"
    link.propertyFields.tooltipText = "toolTip"
    link.showTooltipOn = "hit"

    const hoverState = link.states.create("hover");
    hoverState.properties.fillOpacity = 0.7;
    hoverState.properties.strokeOpacity = 0.7;

    const nodeTemplate = chart.nodes.template;
    nodeTemplate.cursorOverStyle = am4core.MouseCursorStyle.pointer
    nodeTemplate.clickable = false;
    nodeTemplate.draggable = false;

    nodeTemplate.events.on("over", function (event) {
      const node = event.target;
      node.outgoingDataItems.each(function (dataItem: any) {
        if (dataItem.toNode) {
          dataItem.link.isHover = true;
          dataItem.toNode.label.isHover = true;
        }
      })
      node.incomingDataItems.each(function (dataItem) {
        if (dataItem.fromNode) {
          dataItem.link.isHover = true;
          dataItem.fromNode.label.isHover = true;
        }
      })

      node.label.isHover = true;
    })

    nodeTemplate.events.on("out", function (event) {

      const node = event.target;
      node.outgoingDataItems.each(function (dataItem: any) {
        if (dataItem.toNode) {
          dataItem.link.isHover = false;
          dataItem.toNode.label.isHover = false;
        }
      })
      node.incomingDataItems.each(function (dataItem) {
        if (dataItem.fromNode) {
          dataItem.link.isHover = false;
          dataItem.fromNode.label.isHover = false;
        }
      })

      node.label.isHover = false;
    })

    const label = nodeTemplate.label;
    label.relativeRotation = 90;
    label.paddingLeft = 10;
    label.paddingRight = 10;

    label.fillOpacity = 1;
    label.properties.fontWeight = "normal";
    const labelHS = label.states.create("hover");
    labelHS.properties.fontWeight = "bold";
  }

  created() {
    this.getChartData()
    this.createTableNoRelationship()
  }

  beforeDestroy(){
    am4core.disposeAllCharts()
  }

}

